import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useLocation, Redirect } from '@reach/router';
import queryString from 'query-string';
import { clearQueryParams } from 'utils';
import { useWindowSize } from 'hooks';
import {
  AgendaSingleDay,
  EventStreamHeader,
  EventNavbar,
  EventSpeakers,
  Footer,
  Intro,
  ProtectedRoute,
  Kiosk,
  FeelTheJoy,
  FeelTheJoyDay3,
  BreakoutSessions,
  Day3JoyBanner,
  SEO
} from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { AnimatePresence } from 'framer-motion';

const dayTemplate = ({ data }) => {
  const { user } = useContext(FirebaseContext);
  const { selectedEvent, setSelectedEvent } = useContext(LocalContext);
  const { frontmatter: day } = data.day;
  const { did } = day;
  const { pathname } = useLocation();
  const [showJoySessionBanner, setShowJoySessionBanner] = useState(false);

  const { events } = day;

  const allKioskEntriesForThisDay = events
    .map((event) => event.kiosk)
    .filter(Boolean)
    .map(([kioskEntry]) => kioskEntry);

  useEffect(() => {
    if (user) {
      setSelectedEvent({
        ...events[0],
        did
      });
      clearQueryParams(pathname);
    }
    return () => {
      if (user) {
        setSelectedEvent(null);
      }
    };
  }, [user, did]);

  return (
    // <Redirect to="/" noThrow />
    <ProtectedRoute slug={day.slug}>
      {selectedEvent && (
        <>
          <SEO pageSpecificTitle={day.title} />
          <EventNavbar
            colors={selectedEvent.colors}
            eventName={selectedEvent.title}
            slug={day.slug}
            eid={selectedEvent.eid}
            did={selectedEvent.did}
          />
          <EventStreamHeader
            colors={selectedEvent.colors}
            dateAndTimeText={selectedEvent.dateAndTimeText}
            description={selectedEvent.description}
            did={selectedEvent.did}
            eid={selectedEvent.eid}
            dbEventTitle={selectedEvent.dbEventTitle}
            endTime={selectedEvent.endTime}
            eventName={selectedEvent.title}
            eventTitle={selectedEvent.title}
            eventSubtitle={selectedEvent.subtitle}
            startTime={selectedEvent.startTime}
            setSelectedEvent={setSelectedEvent}
            events={events}
            endScreenImg={selectedEvent.endScreenImg}
            gameScreenImg={selectedEvent.gameScreenImg}
            isQAndAEnabled={selectedEvent.isQAndAEnabled}
            isPollsEnabled={selectedEvent.isPollsEnabled}
            isParticipantsEnabled={selectedEvent.isParticipantsEnabled}
            setShowJoySessionBanner={setShowJoySessionBanner}
          />
          {/* {day.did === '3' && <BreakoutSessions />} */}
          {day.did === '3' && (
            <AnimatePresence>
              {showJoySessionBanner && <Day3JoyBanner colors={selectedEvent.colors} />}
            </AnimatePresence>
          )}
          <Intro
            did={selectedEvent.did}
            eid={selectedEvent.eid}
            text={selectedEvent.intro.text}
            colors={selectedEvent.colors}
          />
          {day.did === '3' && <FeelTheJoyDay3 colors={selectedEvent.colors} />}
          {(day.did === '1' || day.did === '2') && (
            <FeelTheJoy colors={selectedEvent.colors} style={{ padding: '0rem 1rem 5rem' }} />
          )}
          {day.did === '1' && selectedEvent.eid === '1_2023' && (
            <Container>
              <Section>
                <EventSpeakers
                  motivationalSpeaker
                  eventSpeakers={day.speakers}
                  colors={selectedEvent.colors}
                />
              </Section>
            </Container>
          )}
          {/* {((day.did === '2' && selectedEvent.eid === '2') ||
            (day.did === '3' && selectedEvent.eid === '4')) && (
            <Container>
              <Section>
                <h2>{selectedEvent.speakers?.length > 1 ? 'Guest Speakers' : 'Guest Speaker'}</h2>
                <EventSpeakers
                  eventSpeakers={selectedEvent.speakers}
                  colors={selectedEvent.colors}
                />
              </Section>
            </Container>
          )} */}
          <AgendaSingleDay
            agenda={selectedEvent.agenda}
            colors={selectedEvent.colors}
            did={did}
            date={`${selectedEvent.dateAndTimeText.split(', ')[0]}`}
          />
          {((selectedEvent.eid === '1_2023' && allKioskEntriesForThisDay.length > 0) ||
            selectedEvent.kiosk) && (
            <Kiosk
              kiosk={selectedEvent.kiosk || allKioskEntriesForThisDay}
              colors={selectedEvent.colors}
            />
          )}
          <Footer />
        </>
      )}
    </ProtectedRoute>
  );
};

const Section = styled.section`
  grid-column: 1/7;

  h2 {
    color: #3c3c3c;
    font-size: 1.5rem;
    margin-bottom: 1em;
    text-align: center;
    width: 100%;
  }

  @media (min-width: 1150px) {
    grid-column: 2/12;
  }

  @media (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const Container = styled.section`
  align-items: flex-start;
  background-color: rgba(196, 196, 196, 0.2);
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 3rem 1rem;
  ${({ style }) => style}

  @media (min-width: 768px) {
    padding: 6rem 1.25rem;
  }
  @media (min-width: 1150px) {
    padding: 6rem 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const query = graphql`
  query ($slug: String!) {
    day: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        did
        slug
        title
        speakers {
          speakerOrder
          name
          position
          linkedinLink
          twLink
          fbLink
          igLink
          summary
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        events {
          eid
          title
          subtitle
          tabTitle
          dateAndTimeText
          colors {
            primary
            secondary
            tertiary
          }
          isQAndAEnabled
          isPollsEnabled
          isParticipantsEnabled
          description
          startTime
          endTime
          endScreenImg {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          intro {
            text
          }
          agenda {
            time {
              jpn
              cet
              est
              gmt
            }
            livestream
            duration
          }
        }
      }
    }
  }
`;

export default dayTemplate;
